.code {
  cursor: pointer;

  color: #dadada;
}
.code:hover {
  text-decoration: underline;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
}

.buttons {
  display: flex;
  gap: 12px;
}
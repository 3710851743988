.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
}

.label {
  font-size: 14px;
  font-weight: 700;

  display: flex;
  align-items: center;
  gap: 8px;
}

.main {
  color: #ffffff;
  font-size: 16px;
  font-weight: 800;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.description {
  color: #989eae;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.buttonGroup {
  display: flex;
  gap: 14px;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 1em;
  overflow: hidden;

  padding: 2em 1em;

  background: repeating-linear-gradient(325deg, #f50404, #f50404 10px, #fe3c3e 10px, #fe3c3e 20px);
  box-shadow: inset 0px -4px 8px rgba(0, 0, 0, 0.25);

  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.big {
  font-size: 38px;
  font-weight: 600;

  strong {
    font-weight: 900;
  }
}

.glow {
  text-shadow: -2px -2px 8px rgba(255, 255, 255, 0.35), 2px 2px 8px rgba(255, 255, 255, 0.35);
}

.small {
  font-size: 18px;
}

.root {
  border-radius: 8px;
  background: rgba(255, 81, 81, 0.15);

  max-width: 500px;
  width: 100%;
  height: 40px;

  cursor: pointer;

  color: #FF5151;

  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.root:hover {
  opacity: 0.85;
}

.root:active {
  opacity: 0.75;
}

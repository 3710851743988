.root {
  width: 100%;
}

.input {
  background-color: rgba(3, 3, 10, 0.33);
  border-color: transparent;

  border-radius: 9px;

  color: white;
  font-size: 14px;
  font-weight: 600;

  height: 45px;
  width: 100%;
  
  padding: 18px;
}
.input::placeholder {
  color: rgba(152, 158, 174, 0.33);
}

.labelGroup {
  display: flex;
  gap: 8px;
}

.label {
  font-size: 14px;
  font-weight: 700;

  margin-bottom: 15px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
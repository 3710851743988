.root {
  border-radius: 7px;
  background: #1d1d2f;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-height: 68px;

  color: #ffffff;

  padding: 18px;
}
